<template>
  <div
    v-if="backgroundpage"
    class="auth-layout"
    v-bind:style="{ background: `url(${backgroundpage})` }"
  >
    <div class="auth-form">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
  <div v-else class="auth-layout">
    <div class="auth-form">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Layout",
  data() {
    return {
      dataShop: [],
      backgroundpage: null,
      homepage: window.location.origin,
      sentences: [],
    };
  },
  async created() {
    this.$root.$refs.baseAuth = this;
    let request = {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    };
    await this.$store.dispatch("getShopById", request);

    let shopId = localStorage.getItem(Constants.SHOP_ID);
    let req = {
      shop_id: shopId,
    };
    let resp = await this.$store.dispatch("checkSentence", req);
    localStorage.setItem(Constants.SENTENCE, JSON.stringify(resp.data));

    await this.getListSettingShopUser(request);
  },
  computed: {
    ...mapGetters(["ListSettingShop"]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].background != null) {
          this.backgroundpage =
            Constants.URL_BE + "/" + this.dataShop[0].background;
          this.backgroundpage = this.backgroundpage.replace(/ /g, "%20");
        }
      }
    },
  },
  methods: {
    ...mapActions({ getListSettingShopUser: "getListSettingShopUser" }),
    changeSentence(content) {
      if (content) {
        let paramStart = content.indexOf("{%");
        let paramEnd = content.indexOf("%}");
        let sentences = JSON.parse(localStorage.getItem(Constants.SENTENCE));
        if (paramStart >= 0 && paramEnd >= 0 && sentences) {
          let param = content.slice(paramStart + 2, paramEnd);
          if (Object.prototype.hasOwnProperty.call(sentences, param)) {
            content = content.replace("{%" + param + "%}", sentences[param]);
          }
        }
      }
      return content;
    },
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "../../views/auth/auth.scss";
</style>
